import React, { useState, useMemo } from "react"
import { Button, Table, TextInput, Pagination, Modal } from "flowbite-react"
import { FaSort, FaSortUp, FaSortDown, FaEdit, FaTrash } from "react-icons/fa"
import MainLayout from "../../layouts/MainLayout"
import { useNavigate } from 'react-router-dom'

interface User {
  id: string
  name: string
  email: string
  role: string
}

const dummyUsers: User[] = [
  { id: "1", name: "John Doe", email: "john@example.com", role: "Admin" },
  { id: "2", name: "Jane Smith", email: "jane@example.com", role: "User" },
  { id: "3", name: "Bob Johnson", email: "bob@example.com", role: "Editor" },
  { id: "4", name: "Alice Brown", email: "alice@example.com", role: "User" },
  { id: "5", name: "Charlie Davis", email: "charlie@example.com", role: "Admin" },
  { id: "6", name: "Eva Wilson", email: "eva@example.com", role: "User" },
  { id: "7", name: "Frank Miller", email: "frank@example.com", role: "Editor" },
  { id: "8", name: "Grace Lee", email: "grace@example.com", role: "User" },
  { id: "9", name: "Henry Taylor", email: "henry@example.com", role: "Admin" },
  { id: "10", name: "Ivy Clark", email: "ivy@example.com", role: "User" },
]

function UsersPage() {
  const [users] = useState<User[]>(dummyUsers)
  const [searchTerm, setSearchTerm] = useState("")
  const [sortConfig, setSortConfig] = useState<{
    key: keyof User
    direction: "asc" | "desc"
  } | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState<User | null>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteResponse, setDeleteResponse] = useState<string | null>(null)
  const itemsPerPage = 5
  const navigate = useNavigate()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    setCurrentPage(1)
  }

  const handleSort = (key: keyof User) => {
    let direction: "asc" | "desc" = "asc"
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc"
    }
    setSortConfig({ key, direction })
  }

  const sortedUsers = useMemo(() => {
    const sortableUsers = [...users]
    if (sortConfig !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1
        }
        return 0
      })
    }
    return sortableUsers
  }, [users, sortConfig])

  const filteredUsers = sortedUsers.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage)
  const displayedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const handleDeleteClick = (user: User) => {
    setUserToDelete(user)
    setShowModal(true)
  }

  const confirmDelete = async () => {
    if (userToDelete) {
      setIsDeleting(true)
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000))
      setDeleteResponse("User deleted successfully")
      setIsDeleting(false)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setUserToDelete(null)
    setDeleteResponse(null)
  }

  const handleEdit = (user: User) => {
    // Implement edit functionality
    console.log("Edit user:", user)
  }

  function getSortIcon(key: keyof User) {
    if (sortConfig?.key !== key) return <FaSort />
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />
  }

  const handleAddUser = () => {
    navigate('/users/add')
  }

  return (
    <MainLayout>
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">User Management</h1>
      <div className="flex justify-between mb-4">
        <Button onClick={handleAddUser}>
          Add User
        </Button>
        <TextInput
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="overflow-x-auto">
        <Table className="min-w-full bg-white dark:bg-gray-800">
          <Table.Head>
            <Table.HeadCell className="w-1/12">Si</Table.HeadCell>
            <Table.HeadCell
              onClick={() => handleSort("name")}
              className="cursor-pointer w-1/4"
            >
              Name
              {getSortIcon("name")}
            </Table.HeadCell>
            <Table.HeadCell
              onClick={() => handleSort("email")}
              className="cursor-pointer w-1/4"
            >
              Email
              {getSortIcon("email")}
            </Table.HeadCell>
            <Table.HeadCell
              onClick={() => handleSort("role")}
              className="cursor-pointer w-1/4"
            >
              Role
              {getSortIcon("role")}
            </Table.HeadCell>
            <Table.HeadCell className="w-1/6">Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {displayedUsers.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={5} className="text-center py-4">
                  No users available at the moment.
                </Table.Cell>
              </Table.Row>
            ) : (
              displayedUsers.map((user, index) => (
                <Table.Row key={user.id}>
                  <Table.Cell>{(currentPage - 1) * itemsPerPage + index + 1}</Table.Cell>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>{user.role}</Table.Cell>
                  <Table.Cell className="flex space-x-4">
                    <FaEdit
                      className="cursor-pointer text-yellow-500 text-xl"
                      onClick={() => handleEdit(user)}
                    />
                    <FaTrash
                      className="cursor-pointer text-red-500 text-xl"
                      onClick={() => handleDeleteClick(user)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
        className="mt-4"
      />

      <Modal show={showModal} onClose={closeModal}>
        <Modal.Header>Confirm Deletion</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {deleteResponse ? (
              <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                {deleteResponse}
              </p>
            ) : (
              <>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete this user?
                </p>
                <p className="text-base font-bold text-gray-700 dark:text-gray-200">
                  {userToDelete?.name} ({userToDelete?.email})
                </p>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {deleteResponse ? (
            <Button onClick={closeModal}>Close</Button>
          ) : (
            <>
              <Button color="red" onClick={confirmDelete} disabled={isDeleting}>
                {isDeleting ? "Deleting..." : "Delete"}
              </Button>
              <Button onClick={closeModal}>Cancel</Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
    </MainLayout>
  )
}

export default UsersPage