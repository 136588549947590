import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Card } from "flowbite-react";
import DomainsTable from "./components/table";
import { useAuth } from "../../utils/helpers/authWrapper";
import Spinner from "../../components/Spinner";
import { DomainData } from "../../utils/types/domains";

const Domain: React.FC = () => {
  const { domains: initialDomains, loading, user } = useAuth();
  const [domains, setDomains] = useState<DomainData[]>([]); 

  useEffect(() => {
    if (initialDomains && initialDomains.length > 0) {
      setDomains(initialDomains);
    }
  }, [initialDomains]);

  return (
    <MainLayout>
      <div className="px-4 pt-6">
        {loading ? (
          <Spinner />
        ) : (
          <Card className="p-4">
            <h1 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
              Domains
            </h1>
            {user && (
              <DomainsTable domains={domains} setDomains={setDomains} user={user} />
            )}
          </Card>
        )}
      </div>
    </MainLayout>
  );
};

export default Domain;
