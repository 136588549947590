import { ChatCount } from '../utils/types/chat';

import axios from './axiosConfig';
import { isAxiosError } from 'axios';

export const getChatCounts = async (
    user_id: number
): Promise<ChatCount[]> => {
  try {
    const response = await axios.post<ChatCount[]>('/get-chat-count', {
      user_id
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error fetching plans:', error.response.data);
    } else {
      console.error('Error fetching plans:', error);
    }
    return [];
  }
};
