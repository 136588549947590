import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import MainLayout from "../../layouts/MainLayout";
import { FaSearch, FaInfoCircle, FaCommentAlt } from "react-icons/fa";
import { useAuth } from "../../utils/helpers/authWrapper";
import { getChatLogs, releaseChat, takeOverChat } from "../../api/chat";
import { ChatLogsResponse, ChatLog } from "../../utils/types/chat";
import {
  Accordion,
  AccordionContent,
  AccordionPanel,
  AccordionTitle,
  Modal,
  Button,
  TextInput,
} from "flowbite-react";
import ChatMessage from "./components";
import { useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import useWebSocket from "react-use-websocket";
import NotificationSound from "../../assets/sound/notification-sound.wav";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const getInitials = (name: string): string => {
  if (!name) return "";
  const nameParts = name.trim().split(" ");
  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  } else {
    return (
      nameParts[0].charAt(0).toUpperCase() +
      nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    );
  }
};

const extractNameAndEmail = (
  message: string
): { name: string; email: string } => {
  const userMessage = message
    .split("\n")
    .find((part) => part.startsWith("User:"));
  if (!userMessage) return { name: "Unknown", email: "Unknown" };

  const nameMatch = RegExp(/name is ([\w\s'.-]+) and/i).exec(userMessage);
  const emailMatch = RegExp(
    /email is (([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})|([A-Z0-9._%+-]+@[A-Z0-9.-]+))/i
  ).exec(userMessage);

  const name = nameMatch ? nameMatch[1].trim() : "Unknown";
  const email = emailMatch ? emailMatch[1].trim() : "Unknown";

  return { name, email };
};

const UserAvatar: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="w-12 h-12 rounded-full flex items-center justify-center bg-blue-100 text-blue-800">
      {getInitials(name)}
    </div>
  );
};

interface ChatLogItemProps {
  log: ChatLog;
  isSelected: boolean;
  isHighlighted: boolean;
  onClick: () => void;
}

const ChatLogItem: React.FC<ChatLogItemProps> = ({
  log,
  isSelected,
  isHighlighted,
  onClick,
}) => {
  const { name } = extractNameAndEmail(log.message);

  const getLastMessage = (message: string): string => {
    const parts = message.split("\n");
    for (let i = parts.length - 1; i >= 0; i--) {
      if (parts[i].startsWith("User:")) {
        return parts[i].replace(/User:|<p>|<\/p>/g, "").trim();
      } else if (parts[i].startsWith("Assistant:")) {
        return (
          "Assistant: " + parts[i].replace(/Assistant:|<p>|<\/p>/g, "").trim()
        );
      }
    }
    return "";
  };

  return (
    <div
      key={log.session_id}
      className={`flex flex-col p-4 border-b cursor-pointer transition-colors duration-200 ${
        isSelected
          ? "bg-blue-50 dark:bg-blue-900"
          : "hover:bg-gray-100 dark:hover:bg-gray-700"
      } ${
        isHighlighted
          ? "bg-yellow-100 dark:bg-yellow-900 border-l-4 border-yellow-500"
          : ""
      }`}
      onClick={onClick}
    >
      <div className="flex items-center">
        <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
          <UserAvatar name={name} />
        </div>
        <div className="ml-4 flex-1 flex justify-between items-center">
          <span className="font-medium text-gray-900 dark:text-gray-100">
            {name}
          </span>
          <span className="text-xs text-gray-500 dark:text-gray-400">
            {new Date(log.created_at).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </div>
      </div>
      <div className="mt-2 text-sm text-gray-500 dark:text-gray-400 overflow-hidden whitespace-nowrap overflow-ellipsis">
        {getLastMessage(log.message)}
      </div>
      {isHighlighted && (
        <div className="mt-2 flex justify-between items-center">
          <span className="text-sm font-semibold text-yellow-600 dark:text-yellow-400">
            Human assistance requested
          </span>
        </div>
      )}
    </div>
  );
};

interface HumanAssistanceRequest {
  domain: string;
  messageID: number;
  reason: string;
  sessionID: string;
  type: string;
}

function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleString("en-US", options);
}

const theme = {
  content: {
    base: "relative h-full w-full p-4 h-auto",
  },
};

const getSenderType = (
  messagePart: string
): "user" | "assistant" | "system" | "human_assistant" => {
  if (messagePart.startsWith("User:")) return "user";
  if (messagePart.startsWith("Support:")) return "human_assistant";
  if (messagePart.startsWith("System:")) return "system";
  return "assistant";
};

function Chats() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [chatLogs, setChatLogs] = useState<ChatLogsResponse>([]);
  const [selectedLog, setSelectedLog] = useState<ChatLog | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDomainId, setSelectedDomainId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSwitched, setIsSwitched] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const [highlightedMessageIds, setHighlightedMessageIds] = useState<
    Set<string>
  >(new Set());
  const [intervenedChats, setIntervenedChats] = useState<Set<string>>(
    new Set()
  );
  const [message, setMessage] = useState("");
  const [lastHumanAssistanceRequest, setLastHumanAssistanceRequest] =
    useState<HumanAssistanceRequest | null>(null);
  const [chatMessages, setChatMessages] = useState<string[]>([]);

  const auth = useAuth();
  const location = useLocation();
  const { width } = useWindowSize();

  const isMobile = width <= 768;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);

  const socketUrl =
    "wss://staging-api.rupeni.com/chat?type=human_listener";
  const supportSocketUrl =
    "wss://staging-api.rupeni.com/chat?type=support";

  const { lastMessage } = useWebSocket(socketUrl);
  const { sendMessage: sendSupportMessage, lastMessage: lastSupportMessage } =
    useWebSocket(supportSocketUrl);

  const updateChatMessages = useCallback((log: ChatLog) => {
    const messages = splitMessages(log.message);
    setChatMessages(messages);
  }, []);

  useEffect(() => {
    if (selectedLog) {
      updateChatMessages(selectedLog);
    }
  }, [selectedLog, updateChatMessages]);

  useEffect(() => {
    audioRef.current = new Audio(NotificationSound);
    audioRef.current.loop = true;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  useEffect(() => {
    if (highlightedMessageIds.size > 0 && hasUserInteracted) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
      if (audioRef.current) audioRef.current.currentTime = 0;
    }
  }, [highlightedMessageIds.size, hasUserInteracted]);

  useEffect(() => {
    const handleUserInteraction = () => setHasUserInteracted(true);
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);
    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        const parsedData = JSON.parse(lastMessage.data);
        console.log(parsedData);
        if (parsedData.type === "human_assistance_request") {
          setLastHumanAssistanceRequest(parsedData);
          setHighlightedMessageIds((prev) =>
            new Set(prev).add(parsedData.sessionID)
          );
          fetchUpdatedLog(parsedData.sessionID);
        }
      } catch (error) {
        console.error("Error parsing lastMessage.data:", error);
      }
    }
  }, [lastMessage]);

  const fetchUpdatedLog = useCallback(async (sessionId: string) => {
    const updatedLog = await getChatLogs(10, 0);
    if (updatedLog && updatedLog.length > 0) {
      setChatLogs((prevLogs) => {
        const updatedLogIndex = prevLogs.findIndex(
          (log) => log.session_id === sessionId
        );
        if (updatedLogIndex !== -1) {
          const newLogs = [...prevLogs];
          newLogs[updatedLogIndex] = updatedLog[0];
          return newLogs;
        }
        return [updatedLog[0], ...prevLogs];
      });

      setSelectedLog((prevSelectedLog) => {
        if (prevSelectedLog && prevSelectedLog.session_id === sessionId) {
          return updatedLog[0];
        }
        return prevSelectedLog;
      });
    }
  }, []);

  const handleIncomingMessage = useCallback(
    (message: string) => {
      if (selectedLog) {
        const updatedMessage = selectedLog.message + "\n\nUser: " + message;
        setChatLogs((prevLogs) =>
          prevLogs.map((log) =>
            log.session_id === selectedLog.session_id
              ? { ...log, message: updatedMessage }
              : log
          )
        );

        const updatedLog = { ...selectedLog, message: updatedMessage };
        setSelectedLog(updatedLog);
        updateChatMessages(updatedLog);
      }
    },
    [selectedLog, updateChatMessages]
  );

  useEffect(() => {
    if (lastSupportMessage !== null) {
      try {
        const parsedMessage = JSON.parse(lastSupportMessage.data);
        console.log(parsedMessage);
        if (
          parsedMessage.type === "chat_message"
        ) {
          handleIncomingMessage(parsedMessage.message);
        }
      } catch (error) {
        console.error("Error parsing support message:", error);
      }
    }
  }, [lastSupportMessage]);

  const handleIntervene = async (session_id: string) => {
    if (lastHumanAssistanceRequest) {
      const { domain, sessionID } = lastHumanAssistanceRequest;
      const success = await takeOverChat(domain, sessionID);

      if (success) {
        setIntervenedChats((prev) => new Set(prev).add(session_id));
        setHighlightedMessageIds((prev) => {
          const newSet = new Set(prev);
          newSet.delete(session_id);
          return newSet;
        });
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }

        if (selectedLog) {
         
          setChatLogs((prevLogs) =>
            prevLogs.map((log) =>
              log.session_id === selectedLog.session_id
                ? { ...log }
                : log
            )
          );

          const updatedLog = { ...selectedLog };
          setSelectedLog(updatedLog);
          updateChatMessages(updatedLog);
        }

        setTimeout(() => messageInputRef.current?.focus(), 0);
      } else {
        console.error("Failed to take over the chat");
      }
    } else {
      console.error("No human assistance request available");
    }
  };

  const handleReleaseChat = async (session_id: string) => {
    if (lastHumanAssistanceRequest) {
      const { domain, sessionID } = lastHumanAssistanceRequest;
      const success = await releaseChat(domain, sessionID);

      if (success) {
        setIntervenedChats((prev) => {
          const newSet = new Set(prev);
          newSet.delete(session_id);
          return newSet;
        });

        if (selectedLog) {
         
          setChatLogs((prevLogs) =>
            prevLogs.map((log) =>
              log.session_id === selectedLog.session_id
                ? { ...log }
                : log
            )
          );

          const updatedLog = { ...selectedLog };
          setSelectedLog(updatedLog);
          updateChatMessages(updatedLog);
        }
      } else {
        console.error("Failed to release the chat");
      }
    } else {
      console.error("No human assistance request available");
    }
  };

  const handleSendMessage = async () => {
    if (message.trim() && lastHumanAssistanceRequest && selectedLog) {
      const { sessionID } = lastHumanAssistanceRequest;

      const payload = JSON.stringify({
        type: "support_message",
        clientID: sessionID,
        message: message,
      });

      sendSupportMessage(payload);

      const updatedMessage = selectedLog.message + "\n\nSupport: " + message;
      setChatLogs((prevLogs) =>
        prevLogs.map((log) =>
          log.session_id === selectedLog.session_id
            ? { ...log, message: updatedMessage }
            : log
        )
      );

      const updatedLog = { ...selectedLog, message: updatedMessage };
      setSelectedLog(updatedLog);
      updateChatMessages(updatedLog);
      setMessage("");
    }
  };

  const handleDropdownClick = () => setDropdownOpen(!dropdownOpen);

  const handleDomainSelect = (domainId: number) => {
    setSelectedDomainId(domainId === 0 ? null : domainId);
    setDropdownOpen(false);
  };

  const sortAndFilterLogs = useCallback(() => {
    const seenIds = new Set();
    return chatLogs
      .filter((log) => {
        const { name, email } = extractNameAndEmail(log.message);
        const lowerCaseQuery = searchQuery.toLowerCase();

        if (seenIds.has(log.id)) return false;
        seenIds.add(log.id);

        return (
          (selectedDomainId === null || log.domain_id === selectedDomainId) &&
          (name.toLowerCase().includes(lowerCaseQuery) ||
            email.toLowerCase().includes(lowerCaseQuery) ||
            log.message.toLowerCase().includes(lowerCaseQuery))
        );
      })
      .sort((a, b) => {
        const aHighlighted = highlightedMessageIds.has(String(a.session_id));
        const bHighlighted = highlightedMessageIds.has(String(b.session_id));
        if (aHighlighted && !bHighlighted) return -1;
        if (!aHighlighted && bHighlighted) return 1;
        return 0;
      });
  }, [chatLogs, searchQuery, selectedDomainId, highlightedMessageIds]);

  const filteredAndSortedLogs = useMemo(
    () => sortAndFilterLogs(),
    [sortAndFilterLogs]
  );

  useEffect(() => {
    const fetchChatLogs = async () => {
      const data = await getChatLogs(10, offset);
      if (data) {
        setChatLogs((prevItems) => {
          const existingIds = new Set(prevItems.map((item) => item.session_id));
          const newItems = data.filter(
            (item) => !existingIds.has(item.session_id)
          );
          const combinedItems = [...prevItems, ...newItems].sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );
          return combinedItems.slice(0, 100);
        });
        setHasMore(data.length > 0);

        if (!selectedLog && isSwitched) {
          setSelectedLog(data[0]);
        }
      } else {
        setHasMore(false);
      }
    };

    fetchChatLogs();
  }, [isSwitched, offset, selectedLog]);

  const fetchMoreChatLogs = () => setOffset((prevOffset) => prevOffset + 10);

  useEffect(() => {
    const stateId = location.state?.id;
    if (stateId && !isSwitched) {
      const selected = chatLogs.find((log) => log.id === stateId);
      if (selected) {
        setSelectedLog(selected);
        setIsSwitched(true);
      }
    }
  }, [chatLogs, isSwitched, location.state?.id]);

  // useEffect(() => {
  //   if (selectedLog) {
  //     const chatContainer = document.querySelector(".chat-container");
  //     if (chatContainer) {
  //       chatContainer.scrollTop = chatContainer.scrollHeight;
  //     }
  //   }
  // }, [selectedLog]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const recentData = await getChatLogs(10, 0);
      if (recentData) {
        setChatLogs((prevItems) => {
          const updatedLogs = prevItems.map((item) => {
            const updatedItem = recentData.find(
              (recentItem) => recentItem.id === item.id
            );
            return updatedItem || item;
          });
          const newItems = recentData.filter(
            (item) => !updatedLogs.some((log) => log.id === item.id)
          );
          return [...newItems, ...updatedLogs]
            .sort((a, b) => b.id - a.id)
            .slice(0, 100);
        });

        setSelectedLog((prevSelectedLog) => {
          if (prevSelectedLog) {
            return (
              recentData.find((log) => log.id === prevSelectedLog.id) ||
              prevSelectedLog
            );
          }
          return prevSelectedLog;
        });
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  
  function splitMessages(message: string) {
    const parts = [];
    const lines = message.split("\n");

    let hasAddedInterventionMessage = false;
  
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line === "") continue;
  
      if (
        line.startsWith("User:") ||
        line.startsWith("Assistant:") ||
        line.startsWith("Support:")
      ) {
        const sender = line.split(":")[0];
        let content = line.substring(line.indexOf(":") + 1).trim();
  
        // Check for first intervention
        if (!hasAddedInterventionMessage && sender === "Support") {
          parts.push("System: Human assistant joined the chat");
          hasAddedInterventionMessage = true;
        }
  
        while (
          i + 1 < lines.length &&
          !lines[i + 1].startsWith("User:") &&
          !lines[i + 1].startsWith("Assistant:") &&
          !lines[i + 1].startsWith("Support:")
        ) {
          i++;
          content += "\n" + lines[i].trim();
        }
  
        parts.push(`${sender}: ${content}`);
  
       
      }
    }
    return parts;
  }

  function Omitstart(message: string) {
    return message
      .replace(/^(User|Assistant|Support|System):\s*/, "")
      .replace(/<p>|<\/p>/g, "");
  }

  return (
    <MainLayout>
      <div className="px-4 pt-6 h-screen max-h-[83vh] overflow-hidden">
        {auth.loading ? (
          <Spinner />
        ) : (
          <div className="border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col rounded-none grid grid-cols-1 md:grid-cols-[25%_75%] p-0 h-full">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="w-full border-r border-gray-300 dark:border-gray-600 p-4 flex flex-col h-full">
              {!isMobile || !selectedLog ? (
                <>
                  <div className="relative mb-4">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                      <FaSearch className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                    </div>
                    <input
                      type="text"
                      id="search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search Messages"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div className="relative w-full mb-4">
                    <button
                      onClick={handleDropdownClick}
                      className="w-full inline-flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {selectedDomainId
                        ? auth.domains.find(
                            (domain) => domain.id === selectedDomainId
                          )?.domain ?? "All Domains"
                        : "All Domains"}
                      <svg
                        className="w-4 h-4 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                    {dropdownOpen && (
                      <div className="absolute w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg z-10 max-h-60 overflow-y-auto overflow-x-hidden ">
                        <button
                          key="all-domains"
                          onClick={() => handleDomainSelect(0)}
                          className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                          All Domains
                        </button>
                        {auth.domains.map((domain) => (
                          <button
                            key={domain.id}
                            onClick={() => handleDomainSelect(domain.id ?? 0)}
                            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 text-nowrap text-ellipsis"
                          >
                            {domain.domain}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  <InfiniteScroll
                    dataLength={chatLogs.length}
                    next={fetchMoreChatLogs}
                    hasMore={hasMore}
                    loader={<Spinner />}
                    scrollableTarget="scrollableDiv"
                  >
                    <div
                      id="scrollableDiv"
                      className="flex-grow overflow-y-auto h-[66vh]"
                    >
                      {filteredAndSortedLogs.length === 0 ? (
                        <div className="text-center text-gray-500 mt-4">
                          No results found.
                        </div>
                      ) : (
                        filteredAndSortedLogs.map((log) => (
                          <ChatLogItem
                            key={log.session_id}
                            log={log}
                            isSelected={
                              selectedLog?.session_id === log.session_id
                            }
                            isHighlighted={highlightedMessageIds.has(
                              log.session_id
                            )}
                            onClick={() => setSelectedLog(log)}
                          />
                        ))
                      )}
                    </div>
                  </InfiniteScroll>
                </>
              ) : (
                <div className="absolute inset-0 bg-white dark:bg-gray-800 p-4 z-10">
                  <button
                    className="text-blue-500 mb-4"
                    onClick={() => setSelectedLog(null)}
                  >
                    &larr; Back to Messages
                  </button>
                  <div className="relative p-4 border-b flex items-center space-x-4">
                    <div className="w-12 h-12 rounded-full flex items-center justify-center bg-white shadow-lg">
                      <div className="w-11 h-11 rounded-full bg-gray-200 flex items-center justify-center text-xl text-gray-700">
                        {selectedLog
                          ? getInitials(
                              extractNameAndEmail(selectedLog.message).name
                            )
                          : getInitials("John Doe")}
                      </div>
                    </div>
                    <div className="flex flex-col justify-center flex-grow">
                      <span className="text-sm">
                        {selectedLog
                          ? extractNameAndEmail(selectedLog.message).name
                          : "John Doe"}
                      </span>
                      <span className="text-xs text-gray-400">
                        {selectedLog
                          ? formatDate(selectedLog.created_at)
                          : formatDate("2024-06-27T21:35:42Z")}
                      </span>
                    </div>
                    <div className="ml-auto">
                      <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <FaInfoCircle />
                      </button>
                    </div>
                  </div>

                  <div className="chat-container overflow-y-auto p-4">
                    {selectedLog && (
                      <div className="text-center text-gray-500 text-xs mb-4">
                        Chat started on {formatDate(selectedLog.created_at)}
                      </div>
                    )}
                    {selectedLog && (
                      <div>
                        {chatMessages.map((messagePart, index) => (
                          <ChatMessage
                            key={`${selectedLog.id}-${index}`}
                            message={Omitstart(messagePart).trim()}
                            sender={getSenderType(messagePart)}
                            initials={getInitials(
                              extractNameAndEmail(selectedLog.message).name
                            )}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <Modal
                    show={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    className="fixed inset-0 flex items-center justify-center"
                    theme={theme}
                  >
                    <Modal.Header>User Details</Modal.Header>
                    <Modal.Body>
                      <div>
                        <p>
                          <strong>Name:</strong>{" "}
                          {extractNameAndEmail(selectedLog?.message || "").name}
                        </p>
                        <p>
                          <strong>Email:</strong>{" "}
                          {
                            extractNameAndEmail(selectedLog?.message || "")
                              .email
                          }
                        </p>
                        <p>
                          <strong>Rating:</strong>{" "}
                          {selectedLog?.rating === ""
                            ? "Not rated"
                            : selectedLog?.rating}
                        </p>
                        <p>
                          <strong>Feedback:</strong>{" "}
                          {selectedLog?.feedback === ""
                            ? "No feedback"
                            : selectedLog?.feedback}
                        </p>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setIsModalOpen(false)}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              )}
            </div>
            {!isMobile && (
              <div className="flex flex-col h-full">
                <div className="relative p-4 border-b border-gray-300 dark:border-gray-600 flex items-center space-x-4">
                  <div className="w-12 h-12 rounded-full flex items-center justify-center bg-white shadow-lg">
                    <div className="w-11 h-11 rounded-full bg-gray-200 flex items-center justify-center text-xl text-gray-700">
                      {selectedLog
                        ? getInitials(
                            extractNameAndEmail(selectedLog.message).name
                          )
                        : getInitials("U N")}
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <span className="text-sm dark:text-gray-100">
                      {selectedLog
                        ? extractNameAndEmail(selectedLog.message).name
                        : "No User"}
                    </span>
                    <span className="text-xs text-gray-400">
                      {selectedLog ? formatDate(selectedLog.created_at) : ""}
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-[65%_35%] h-full">
                  <div className=" h-full relative">
                    <div
                      className="chat-container overflow-y-auto p-4 flex-grow"
                      style={{ height: "60vh" }}
                    >
                      {selectedLog ? (
                        <>
                          <div className="text-center text-gray-500 text-xs mb-4">
                            Chat started on {formatDate(selectedLog.created_at)}
                          </div>
                          <div>
                            {chatMessages.map((messagePart, index) => (
                              <ChatMessage
                                key={`${selectedLog.id}-${index}`}
                                message={Omitstart(messagePart).trim()}
                                sender={getSenderType(messagePart)}
                                initials={getInitials(
                                  extractNameAndEmail(selectedLog.message).name
                                )}
                              />
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="flex flex-col items-center justify-center h-[calc(100vh-240px)] pb-34">
                          <FaCommentAlt className="text-gray-500 text-6xl mb-4" />
                          <p className="text-gray-500">
                            Please Select a Conversation.
                          </p>
                        </div>
                      )}
                    </div>
                    {lastHumanAssistanceRequest &&
                      intervenedChats.has(
                        lastHumanAssistanceRequest.sessionID
                      ) && (
                        <div className="p-4 border-t absolute w-full">
                          <div className="flex items-center space-x-2 w-full">
                            <TextInput
                              ref={messageInputRef}
                              type="text"
                              placeholder="Type your message..."
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              onKeyDown={(e) =>
                                e.key === "Enter" && handleSendMessage()
                              }
                              className="flex-grow"
                            />
                            <Button onClick={handleSendMessage}>Send</Button>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="border-l border-gray-300 dark:border-gray-600 h-full p-4">
                    <div className="pt-4">
                      <Accordion>
                        <AccordionPanel>
                          <AccordionTitle>User Details</AccordionTitle>
                          <AccordionContent>
                            <div className="flex flex-col space-y-4">
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p className="break-words dark:text-gray-100">
                                  <strong>Name:</strong>{" "}
                                  {
                                    extractNameAndEmail(
                                      selectedLog?.message ?? ""
                                    ).name
                                  }
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p className="break-words dark:text-gray-100">
                                  <strong>Email:</strong>{" "}
                                  {
                                    extractNameAndEmail(
                                      selectedLog?.message ?? ""
                                    ).email
                                  }
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p className="break-words dark:text-gray-100">
                                  <strong>Country:</strong>{" "}
                                  {selectedLog?.country === ""
                                    ? "Not provided"
                                    : selectedLog?.country}
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p className="break-words dark:text-gray-100">
                                  <strong>Rating:</strong>{" "}
                                  {selectedLog?.rating === ""
                                    ? "Not rated"
                                    : selectedLog?.rating}
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                <p className="break-words dark:text-gray-100">
                                  <strong>Feedback:</strong>{" "}
                                  {selectedLog?.feedback === ""
                                    ? "No feedback"
                                    : selectedLog?.feedback}
                                </p>
                              </div>
                              {selectedLog &&
                                highlightedMessageIds.has(
                                  selectedLog.session_id
                                ) && (
                                  <Button
                                    color="success"
                                    onClick={() =>
                                      handleIntervene(selectedLog.session_id)
                                    }
                                  >
                                    Intervene Chat
                                  </Button>
                                )}
                              {selectedLog &&
                                intervenedChats.has(selectedLog.session_id) && (
                                  <>
                                    <div className="text-green-600 font-semibold">
                                      Agent Chat in progress
                                    </div>
                                    <Button
                                      color="warning"
                                      onClick={() =>
                                        handleReleaseChat(
                                          selectedLog.session_id
                                        )
                                      }
                                    >
                                      Release Chat
                                    </Button>
                                  </>
                                )}
                            </div>
                          </AccordionContent>
                        </AccordionPanel>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default Chats;
