import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Installation from "./pages/Installation";
import AuthGuard from "./utils/helpers/authWrapper";
import ResetPW from "./pages/ResetPW";
import Domain from "./pages/Domain";
import AddDomain from "./pages/Domain/add";
import Plans from "./pages/Plans";
import ViewEditDomain from "./pages/Domain/view";
import Chats from "./pages/Chats";
// import ComingSoon from "./pages/ComingSoon";
import Train from "./pages/Domain/train";
import Landing from "./pages/Landing";
import { initGA, logPageView } from "./utils/googleanalytics";
import Users from "./pages/Users";
import Billing from "./pages/Billing";
// import AIConversations from "./pages/AIConversations";

const AnimatedRoutes: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Routes location={location}>
      <Route path="/" element={<Landing />} />
      <Route path="/home" element={<Landing />} />
      {/* <Route path= "/test" element={<AIConversations />} /> */}
      <Route path="/authentication" element={<Login />} />
      <Route path="/reset" element={<ResetPW />} />
      <Route
        path="/dashboard"
        element={
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        }
      />
      <Route
        path="/installation"
        element={
          <AuthGuard>
            <Installation />
          </AuthGuard>
        }
      />
      <Route
        path="/domains"
        element={
          <AuthGuard>
            <Domain />
          </AuthGuard>
        }
      />
      <Route
        path="/domains/add"
        element={
          <AuthGuard>
            <AddDomain />
          </AuthGuard>
        }
      />
      <Route
        path="/domains/view"
        element={
          <AuthGuard>
            <ViewEditDomain />
          </AuthGuard>
        }
      />
      <Route
        path="/plans"
        element={
          <AuthGuard>
            <Plans />
          </AuthGuard>
        }
      />
      <Route
        path="/chats"
        element={
          <AuthGuard>
            <Chats />
          </AuthGuard>
        }
      />

      <Route
        path="/users"
        element={
          <AuthGuard>
            <Users />
          </AuthGuard>
        }
      />

      <Route
        path="/domains/chatbot-training"
        element={
          <AuthGuard>
            <Train />
          </AuthGuard>
        }
      />

      <Route
        path="/billing"
        element={
          <AuthGuard>
            <Billing />
          </AuthGuard>
        }
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
};

export default App;
