import React, {  useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Button, Card, TextInput } from "flowbite-react";

// import { getPlans } from "../../api/paymentplans";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaCreditCard,
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
} from "react-icons/fa";
import { getCardType } from "../../utils/helpers/getCardType";

function Billing() {
//   const [loading, setLoading] = useState(true);
  const [statementsPage, setStatementsPage] = useState(1);
  const [paymentHistoryPage, setPaymentHistoryPage] = useState(1);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    name: "",
    cardNumber: "",
    cvv: "",
    expiry: "",
  });

  const statementsData = [
    {
      date: "July 1, 2024",
      balanceDue: "$100.00",
      plan: "Pro Plan",
      total: "$100.00",
    },
    {
      date: "June 1, 2024",
      balanceDue: "$0.00",
      plan: "Pro Plan",
      total: "$100.00",
    },
    {
      date: "May 1, 2024",
      balanceDue: "$0.00",
      plan: "Basic Plan",
      total: "$50.00",
    },
    {
      date: "April 1, 2024",
      balanceDue: "$0.00",
      plan: "Basic Plan",
      total: "$50.00",
    },
    {
      date: "March 1, 2024",
      balanceDue: "$0.00",
      plan: "Free Plan",
      total: "$0.00",
    },
    {
      date: "February 1, 2024",
      balanceDue: "$0.00",
      plan: "Free Plan",
      total: "$0.00",
    },
  ];

  const [cardType, setCardType] = useState("default");

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCardNumber = e.target.value;
    setPaymentInfo({ ...paymentInfo, cardNumber: newCardNumber });
    setCardType(getCardType(newCardNumber));
  };

  const getCardIcon = (type: string) => {
    switch (type) {
      case "visa":
        return <FaCcVisa style={{ color: "white" }} />;
      case "mastercard":
        return <FaCcMastercard style={{ color: "white" }} />;
      case "amex":
        return <FaCcAmex style={{ color: "white" }} />;
      case "discover":
        return <FaCcDiscover style={{ color: "white" }} />;
      default:
        return <FaCreditCard style={{ color: "white" }} />;
    }
  };
  const paymentHistoryData = [
    { date: "8 OCT 2024", amount: "$100.00", status: "PENDING" },
    { date: "8 SEP 2024", amount: "$100.00", status: "COMPLETE" },
    { date: "8 AUG 2024", amount: "$100.00", status: "COMPLETE" },
    { date: "8 JUL 2024", amount: "$50.00", status: "COMPLETE" },
    { date: "8 JUN 2024", amount: "$50.00", status: "COMPLETE" },
    { date: "8 MAY 2024", amount: "$50.00", status: "COMPLETE" },
  ];

  const itemsPerPage = 5;
  const statementsPageCount = Math.ceil(statementsData.length / itemsPerPage);
  const paymentHistoryPageCount = Math.ceil(
    paymentHistoryData.length / itemsPerPage
  );

  function Pagination({
    currentPage,
    pageCount,
    setPage,
  }: {
    currentPage: number;
    pageCount: number;
    setPage: (page: number) => void;
  }) {
    return (
      <div className="flex justify-center items-center mt-4 ">
        {Array.from({ length: pageCount }, (_, i) => i + 1).map(
          (pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => setPage(pageNumber)}
              className={`px-3 py-1 text-sm ${
                currentPage === pageNumber
                  ? "font-bold underline text-blue-600 dark:text-blue-400"
                  : "text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
              }`}
            >
              {pageNumber}
            </button>
          )
        )}
      </div>
    );
  }

//   useEffect(() => {
//     async function fetchPlans() {
//       try {
//         const fetchedPlans = await getPlans();
//         setPlans(fetchedPlans || []);
//       } catch (error) {
//         console.error("Failed to fetch plans:", error);
//       } finally {
//         setLoading(false);
//       }
//     }

//     fetchPlans();
//   }, []);

  const handleAddPaymentMethod = () => {
    setShowPaymentForm(true);
  };

  const handleSavePaymentInfo = () => {
    // Here you would typically send this information to your backend
    console.log("Payment info saved:", paymentInfo);
    setHasPaymentMethod(true);
    setShowPaymentForm(false);
  };

//   if (loading) {
//     return (
//       <MainLayout>
//         <div className="flex justify-center items-center h-screen">
//           <Spinner size="xl" />
//         </div>
//       </MainLayout>
//     );
//   }

  return (
    <MainLayout>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 px-4 py-6">
        <div className="lg:col-span-1 space-y-4">
          <Card className="px-4 py-2 dark:bg-gray-800 text-white bg-[#383838] text-center">
            <h1 className="text-gray-200">BALANCE DUE</h1>
            <h1 className="text-2xl font-bold">$100</h1>
            <h1 className="text-gray-200">Free Plan</h1>
            {!hasPaymentMethod && (
              <Button
                className="rounded-md bg-primary text-black w-full mt-2 hover:text-white"
                onClick={handleAddPaymentMethod}
              >
                Add Payment Method
              </Button>
            )}
          </Card>

          <Card className="px-4 py-2 dark:bg-gray-800 text-white bg-[#383838] text-start">
            <h1 className="font-bold">AUTOMATIC PAYMENT METHOD</h1>
            <AnimatePresence>
              {!showPaymentForm ? (
                <span className="text-gray-200">
                  {hasPaymentMethod ? "Card ending in ****" : "None"}
                </span>
              ) : (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="space-y-4 mt-2">
                    <TextInput
                      type="text"
                      placeholder="NAME (AS IT APPEARS ON CARD)"
                      value={paymentInfo.name}
                      onChange={(e) =>
                        setPaymentInfo({ ...paymentInfo, name: e.target.value })
                      }
                      theme={{
                        addon:
                          "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-300 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
                        field: {
                          input: {
                            base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-gray-300 focus:border-gray-500 focus:ring-gray-500",
                            colors: {
                              primary:
                                "border-gray-300 bg-[#727272] text-white placeholder-gray-400 focus:border-gray-500",
                            },
                          },
                        },
                      }}
                      color="primary"
                    />
                    <TextInput
                      type="text"
                      placeholder="CARD NUMBER"
                      value={paymentInfo.cardNumber}
                      onChange={handleCardNumberChange}
                      theme={{
                        addon:
                          "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-300 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
                        field: {
                          input: {
                            base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-gray-300 focus:border-gray-500 focus:ring-gray-500 pl-10",
                            colors: {
                              primary:
                                "border-gray-300 bg-[#727272] text-white placeholder-gray-400 focus:border-gray-500",
                            },
                          },
                        },
                      }}
                      color="primary"
                      icon={(props) =>
                        React.cloneElement(getCardIcon(cardType), props)
                      }
                    />

                    <TextInput
                      type="text"
                      placeholder="CVV"
                      value={paymentInfo.cvv}
                      onChange={(e) =>
                        setPaymentInfo({ ...paymentInfo, cvv: e.target.value })
                      }
                      theme={{
                        addon:
                          "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-300 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
                        field: {
                          input: {
                            base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-gray-300 focus:border-gray-500 focus:ring-gray-500",
                            colors: {
                              primary:
                                "border-gray-300 bg-[#727272] text-white placeholder-gray-400 focus:border-gray-500",
                            },
                          },
                        },
                      }}
                      color="primary"
                    />
                    <TextInput
                      type="text"
                      placeholder="MM/YY"
                      value={paymentInfo.expiry}
                      onChange={(e) =>
                        setPaymentInfo({
                          ...paymentInfo,
                          expiry: e.target.value,
                        })
                      }
                      theme={{
                        addon:
                          "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-300 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
                        field: {
                          input: {
                            base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-gray-300 focus:border-gray-500 focus:ring-gray-500",
                            colors: {
                              primary:
                                "border-gray-300 bg-[#727272] text-white placeholder-gray-400 focus:border-gray-500",
                            },
                          },
                        },
                      }}
                      color="primary"
                    />

                    <Button
                      className="w-full bg-transparent border-white"
                      onClick={handleSavePaymentInfo}
                    >
                      Save Payment Information
                    </Button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <hr className="my-3 border-t-2 border-white" />

            <h1 className="font-bold">Current Billing Cycle:</h1>

            <div className="flex flex-col">
              <span className="text-gray-200">
                Billing Period:{" "}
                <span className="text-white font-bold">
                  July 2024 - August 2024
                </span>
              </span>
              <span className="text-gray-200">
                Payment Due Date:{" "}
                <span className="text-white font-bold">August 15, 2024</span>
              </span>
            </div>

            <span className="text-gray-200 text-xs mt-2 block">
              *Your next payment will be due on the first day of your billing
              cycle
            </span>
          </Card>
        </div>
        <div className="lg:col-span-3 space-y-6">
          <Card className="p-4 dark:bg-gray-800 bg-white">
            <h1 className="text-2xl font-bold text-start text-gray-900 dark:text-gray-100 mb-4">
              STATEMENTS
            </h1>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                  <tr className="border-b-2 border-gray-300">
                    <th scope="col" className="font-normal pb-3">
                      INVOICE DATE
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      BALANCE DUE
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      PLAN
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      TOTAL
                    </th>
                    <th
                      scope="col"
                      className="font-normal px-6 pb-3 text-center"
                    >
                      DOWNLOAD
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statementsData
                    .slice(
                      (statementsPage - 1) * itemsPerPage,
                      statementsPage * itemsPerPage
                    )
                    .map((row, index, array) => (
                      <tr
                        key={index}
                        className={`font-bold text-black ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        <td className="py-4">{row.date}</td>
                        <td className="px-6 py-4">{row.balanceDue}</td>
                        <td className="px-6 py-4">{row.plan}</td>
                        <td className="px-6 py-4">{row.total}</td>
                        <td className="px-6 py-4">
                          <div className="flex justify-center items-center h-full">
                            <button
                              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
                              aria-label="Download invoice"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-blue-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={statementsPage}
              pageCount={statementsPageCount}
              setPage={setStatementsPage}
            />
          </Card>

          <Card className="p-4 dark:bg-gray-800 bg-white">
            <h1 className="text-2xl font-bold text-start mb-4 text-gray-900 dark:text-gray-100">
              PAYMENT HISTORY
            </h1>
            <div className="overflow-x-auto">
              <div className="w-full grid grid-cols-3 text-sm text-gray-500 dark:text-gray-400">
                {paymentHistoryData
                  .slice(
                    (paymentHistoryPage - 1) * itemsPerPage,
                    paymentHistoryPage * itemsPerPage
                  )
                  .map((payment, index, array) => (
                    <React.Fragment key={index}>
                      <div
                        className={`py-4 font-bold text-black ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        {payment.date}
                      </div>
                      <div
                        className={`py-4 text-center font-bold text-black ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        {payment.amount}
                      </div>
                      <div
                        className={`py-4 text-right font-bold text-black ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        {payment.status}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
            <Pagination
              currentPage={paymentHistoryPage}
              pageCount={paymentHistoryPageCount}
              setPage={setPaymentHistoryPage}
            />
          </Card>
        </div>
      </div>
    </MainLayout>
  );
}

export default Billing;
