import { Sidebar } from "flowbite-react";
import type { FC, MouseEvent } from "react";
import { useEffect, useState } from "react";
import {
  HiChartPie,
  HiGlobe,
  HiChatAlt2,
  HiLogout,
  HiQuestionMarkCircle,
  HiUserGroup,
} from "react-icons/hi";
import { useSidebarContext } from "../context/useSideBarContext";
import classNames from "classnames";
import "./styles.css";
import { logoutUser } from "../../api/authentication";
import { useAuth } from "../../utils/helpers/authWrapper";

const MainSidebar: FC = () => {
  const [currentPage, setCurrentPage] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const { isOpenOnSmallScreens } = useSidebarContext();
  const { expired, user, loading } = useAuth();
  const theme = {
    root: {
      base: "h-full",
      collapsed: {
        on: "w-16",
        off: "w-64",
      },
      inner:
        "h-full overflow-y-auto overflow-x-hidden rounded bg-bg px-3 py-4 ",
    },
    item: {
      base: "flex items-center justify-center rounded-lg p-2 text-base font-normal text-white hover:bg-primary hover:text-bg dark:text-white dark:hover:bg-gray-700",
    },
  };

  useEffect(() => {
    const newPage = window.location.pathname;
    setCurrentPage(newPage);
  }, []);

  const handleLogout = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await logoutUser();
    window.location.href = "/authentication";
  };

  const getInitials = (name: string): string => {
    const nameParts = name.split(" ");
    const initials = nameParts
      .map((part) => part.charAt(0).toUpperCase())
      .join("");
    return initials;
  };

  const renderSidebarItem = (href: string, icon: FC, label: string) => {
    const isDisabled =
      expired && !["/plans", "/profile", "/logout"].includes(href);
    return (
      <Sidebar.Item
        href={isDisabled ? undefined : href}
        icon={icon}
        className={classNames({
          "bg-primary dark:bg-gray-700 text-secondary": href === currentPage,
          "cursor-not-allowed opacity-50": isDisabled,
          "cursor-pointer": !isDisabled,
        })}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          if (isDisabled) e.preventDefault();
        }}
      >
        <span
          className={classNames("ml-2", {
            hidden: !isHovered && !isOpenOnSmallScreens,
          })}
        >
          {label}
        </span>
      </Sidebar.Item>
    );
  };

  return (
<nav

  className={classNames("sidebar duration-300 ease-in-out h-full", {
    "sidebar-collapsed lg:w-16": !isHovered && !isOpenOnSmallScreens,
    "sidebar-expanded lg:w-64": isHovered || isOpenOnSmallScreens,
    "w-full absolute z-50": isOpenOnSmallScreens,
  })}
  onMouseEnter={() => setIsHovered(true)}
  onMouseLeave={() => setIsHovered(false)}
>
  <Sidebar
    theme={theme}
    className="bg-bg border-r border-r-gray-200 overflow-hidden dark:border-r-gray-700 rounded-none fixed left-0 h-[calc(100vh-80px)] mt-20"
  >
    <div
      className={classNames(
        "flex flex-col py-4 border-b sidebar-transition",
        {
          "items-start": !isHovered && !isOpenOnSmallScreens,
          "items-center": isHovered || isOpenOnSmallScreens,
        }
      )}
    >
      {loading ? (
        <output
          className="animate-pulse flex flex-col items-center"
        >
          <svg
            className={classNames("mb-3 text-gray-200 dark:text-gray-700", {
              "w-8 h-8 lg:w-10 lg:h-10":
                !isHovered && !isOpenOnSmallScreens,
              "w-16 h-16": isHovered || isOpenOnSmallScreens,
            })}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          {(isHovered || isOpenOnSmallScreens) && (
            <>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-3"></div>
              <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </>
          )}
        </output>
      ) : (
        <>
          <div
            className={classNames(
              "rounded-full bg-gray-200 flex items-center justify-center text-xl text-gray-700 transition-all duration-300",
              {
                "w-8 h-8 lg:w-10 lg:h-10":
                  !isHovered && !isOpenOnSmallScreens,
                "w-16 h-16": isHovered || isOpenOnSmallScreens,
              }
            )}
          >
            {getInitials(user?.name ?? "")}
          </div>
          {(isHovered || isOpenOnSmallScreens) && (
            <>
              <p className="mt-2 font-semibold text-primary dark:text-gray-100 hidden lg:block">
                {user?.name}
              </p>
              <p className="text-sm text-gray-500 hidden lg:block">
                {user?.email}
              </p>
            </>
          )}
        </>
      )}
    </div>

    <div className="flex flex-col justify-between py-2 rounded-none">
      <div>
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {renderSidebarItem("/dashboard", HiChartPie, "Dashboard")}
            {renderSidebarItem("/domains", HiGlobe, "Domains")}
            {renderSidebarItem("/chats", HiChatAlt2, "Chats")}
            {renderSidebarItem("/users", HiUserGroup, "Users")}
            {renderSidebarItem(
              "/installation",
              HiQuestionMarkCircle,
              "Installation"
            )}
            <Sidebar.Item
              onClick={handleLogout}
              icon={HiLogout}
              className="cursor-pointer"
            >
              <span
                className={classNames("ml-2", {
                  hidden: !isHovered && !isOpenOnSmallScreens,
                })}
              >
                Logout
              </span>
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </div>
    </div>
  </Sidebar>
</nav>


  );
};

export default MainSidebar;
