import React, { useEffect, useState, memo } from "react";
import type { FC } from "react";
import {
  Avatar,
  DarkThemeToggle,
  Dropdown,
  Navbar,
  Badge,
} from "flowbite-react";
import { rupeni } from "../../assets/images";
import { useSidebarContext } from "../context/useSideBarContext";
import isSmallScreen from "../../utils/helpers/is-small-screen";
import { HiX, HiMenuAlt1 } from "react-icons/hi";
import { useAuth } from "../../utils/helpers/authWrapper";
import { logoutUser } from "../../api/authentication";
import PlansView from "../plans";
import { Plan } from "../../utils/types/paymentplans";
import { getPlans } from "../../api/paymentplans";

const MainNavbar: React.FC = function () {
  const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =
    useSidebarContext();
  const { user } = useAuth();
  const [showPlans, setShowPlans] = useState(false);
  const [plans, setPlans] = useState<Plan[]>([]);

  const handleBadgeClick = () => {
    setShowPlans(!showPlans);
  };

  useEffect(() => {
    getPlans().then((data) => {
      setPlans(data || []);
    });
  }, []);

  return (
    <Navbar
      fluid
      className="border-b border-b-gray-200 bg-black dark:bg-gray-700 fixed top-0 z-[999999] w-full"
    >
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline"
              >
                <span className="sr-only">Toggle sidebar</span>
                {isOpenOnSmallScreens && isSmallScreen() ? (
                  <HiX className="h-6 w-6" />
                ) : (
                  <HiMenuAlt1 className="h-6 w-6" />
                )}
              </button>
            )}
            <Navbar.Brand href="/" className="flex items-center">
              <img
                alt="Rupeni AI Logo"
                src={rupeni}
                className="dark:hidden mr-3 h-6 sm:h-8 block w-auto"
              />
              <img
                alt="Rupeni AI Logo"
                src={rupeni}
                className="hidden dark:block mr-3 h-6 sm:h-8 w-auto"
              />
              <span className="self-center whitespace-nowrap text-2xl text-primary font-semibold dark:text-white hidden lg:block">
                Rupeni AI
              </span>
            </Navbar.Brand>
          </div>
          <div className="flex items-center lg:gap-3">
            {user && (
              <div className="relative">
                <Badge
                  color="info"
                  className="text-sm py-2 px-2 cursor-pointer"
                  onClick={handleBadgeClick}
                >
                  {user.plan_name} Plan
                </Badge>
                {showPlans && (
                  <PlansView plans={plans} selectedPlanId={user.plan_id} />
                )}
              </div>
            )}
            <div className="flex items-center">
              <DarkThemeToggle />
            </div>
            <div className="lg:block">
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

const UserDropdown: FC = memo(function () {
  const { user, domains } = useAuth();

  const handleLogout = async () => {
    await logoutUser();
    window.location.href = "/authentication";
  };

  const theme = {
    arrowIcon: "ml-2 h-4 w-4 text-white",
  };

  return (
    <Dropdown
      arrowIcon={true}
      inline
      label={
        <span>
          <span className="sr-only">User menu</span>
          {domains && domains.length > 0 ? (
            <Avatar
              alt={user?.name}
              img={domains[0].chat_bot_logo}
              rounded
              size="sm"
            />
          ) : (
            <></>
          )}
        </span>
      }
      theme={theme}
    >
      <Dropdown.Header>
        <span className="block text-sm">{user?.name}</span>
        <span className="block truncate text-sm font-medium">
          {user?.email}
        </span>
      </Dropdown.Header>
      <Dropdown.Item href="/installation">How-to guide</Dropdown.Item>
      <Dropdown.Item href="/profile">Settings</Dropdown.Item>
      <Dropdown.Item href="/billing">Billing</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
    </Dropdown>
  );
});

export default MainNavbar;
